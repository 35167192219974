import { createSlice } from "@reduxjs/toolkit";

export interface CompPreviewParams {
  composite_id: number | null,
  account_id: number | null;
  year: number | null;
  month: number | null;
}

export const DEFAULT_COMP_PREVIEW_PARAMS = {
  composite_id: null,
  account_id: null,
  year: null,
  month: null,
};

export interface ReportingState {
  compPreviewParams: CompPreviewParams;
}

const initialState: ReportingState = {
  compPreviewParams: DEFAULT_COMP_PREVIEW_PARAMS,
};

const reportingSlice = createSlice({
  name: "reporting",
  initialState,
  reducers: {
    setCompPreviewParams: (state, action) => {
      state.compPreviewParams = action.payload;
    },
  },
});

export default reportingSlice.reducer;
export const {
  setCompPreviewParams,
} = reportingSlice.actions;
