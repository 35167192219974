import {
  FooterPropsOverrides,
  GridColDef,
  GridEditInputCell,
  GridFooter,
  GridRenderCellParams,
  GridTreeNodeWithRender,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import {
  ServerDataGrid,
  qtyFormatterWhole,
  useGridFilters,
  usePagination,
  useSorting,
  availableQtyFormatter,
} from "components/ui/ServerDataGrid";
import {
  useListStrategyPositionsQuery,
  useUpdateAccountStrategyMutation,
  StrategyPosition,
  useRemoveAccountFromStrategyMutation,
  RemoveAccountFromStrategyParams,
  useOverridePositionQueryMutation,
} from "features/trading/accounts";
import { useAppDispatch, useAuth, useReporting, useTrading } from "features/store";
import {
  DraftOrder,
  TradeMode,
  setDraftOrders,
} from "features/trading/tradingSlice";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getExecutedOrder, getOnOrder, getOptionQty, getPositionHighlightColor } from "helpers";
import { Box, Divider, Grid, IconButton, Popover, Tooltip, Typography } from "@mui/material";
import { CompositesData, OrderType, StrategyGroupEnum } from "constants/index";
import RemoveIcon from '@mui/icons-material/Remove';
import ConfirmIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Close";
import SummarizeIcon from "@mui/icons-material/Summarize";
import { DEFAULT_COMP_PREVIEW_PARAMS, setCompPreviewParams } from "features/reporting/reportingSlice";
import { CRPModal } from "./CRPModal";

const createColumns = (
  tradeMode: TradeMode = TradeMode.CallSellShort,
  hedgeRatio: number,
  draftOrders: DraftOrder[],
  actionsCol: GridColDef<StrategyPosition>,
): GridColDef<StrategyPosition>[] => {
  const calcTradeQty = (
    params: GridValueGetterParams<
      StrategyPosition,
      any,
      GridTreeNodeWithRender
    >,
  ) => {
    return draftOrders.find(
      (draftOrder) => draftOrder.account === params.row.id,
    )?.quantity;
  };

  return [
    { field: "id", headerName: "ID", width: 90, filterable: false },
    { field: "client", headerName: "Client ID", width: 150 },
    {
      field: "orion_account_id",
      headerName: "Orion Account ID",
      width: 150,
      filterable: false,
    },
    { field: "client__name", headerName: "Client Name", width: 250 },
    {
      field: "account_number",
      headerName: "Account #",
      width: 150,
      filterable: false,
    },
    { field: "reg_type__name", headerName: "Account Type", width: 150 },
    {
      field: "custodian__name",
      headerName: "Custodian",
      width: 150,
      filterable: false,
    },
    {
      field: "client__representative__name",
      headerName: "Representative",
      width: 150,
      filterable: false,
    },
    {
      field: "stock_qty",
      headerName: "Stock Qty",
      filterable: false,
      type: "number",
      renderCell: qtyFormatterWhole,
    },
    {
      field: "available_stock_qty",
      headerName: "Allowed to Hedge",
      filterable: false,
      type: "number",
      width: 150,
      renderCell: availableQtyFormatter,
      editable: true,
      renderEditCell: (params) => (
        <GridEditInputCell
          {...params}
          inputProps={{
            min: 0,
          }}
          onKeyPress={(event: any) => {
            if (event.code === "Minus") {
              event.preventDefault();
            }
          }}
        />
      ),
    },
    {
      field: "option_qty",
      headerName: "Option Qty",
      type: "number",
      filterable: false,
      width: 150,
      valueGetter: ({ value, row }: GridValueGetterParams) => {
        const numValue = getOptionQty(row) || 0;
        const executedOrderQty = getExecutedOrder(row);
        return (numValue + executedOrderQty).toString();
      },
      renderCell: ({ value, row }: GridRenderCellParams) => {
        const formattedValue = parseFloat(value || 0).toFixed(0);
        const highlightColor = getPositionHighlightColor(row);

        return <span style={{ background: highlightColor }}>{formattedValue}</span>;
      },
      editable: true,
      renderEditCell: (params) => {
        return (
          <GridEditInputCell
            {...params}
          />
        );
      },
    },
    {
      field: "new_option_pos",
      headerName: "Allowed Position (old)",
      type: "number",
      filterable: false,
      width: 150,
      valueGetter: (params: GridValueGetterParams) => {
        return (-Math.floor(
          (parseFloat(
            ((params.row.available_stock_qty === null
              ? params.row.stock_qty
              : params.row.available_stock_qty) as string) || "0",
          ) *
            hedgeRatio) /
            100,
        )).toFixed(0);
      },
      renderCell: (params: GridRenderCellParams) => {
        const newOptionPos = parseInt(params.value || "0")
        const optionQty = getOptionQty(params.row) || 0;
        const isUnexpected = newOptionPos !== optionQty && optionQty !== 0;
        return (
          <div style={{ color: isUnexpected ? "red" : "inherit" }}>
            {qtyFormatterWhole(params)}
          </div>
        );
      },
    },
    {
      field: "allowed_position",
      headerName: "Allowed Position",
      type: "number",
      filterable: false,
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        const allowedPos = parseInt(params.value || "0");
        const optionQty = getOptionQty(params.row) || 0;
        const isUnexpected = optionQty !== allowedPos && optionQty !== 0;
        return (
          <div style={{ color: isUnexpected ? "red" : "inherit" }}>
            {qtyFormatterWhole(params)}
          </div>
        );
      },
    },
    {
      field: "on_order",
      headerName: "On Order",
      align: "right",
      width: 100,
      valueGetter: ({ row }: GridValueGetterParams) => getOnOrder(row),
    },
    {
      field: "trade_qty",
      headerName: "Trade Qty",
      align: "right",
      type: "number",
      editable: true,
      width: 100,
      valueGetter: calcTradeQty,
      renderCell: (params) => (
        <GridEditInputCell
          {...params}
          type="number"
          inputProps={{
            min: 0,
          }}
          onKeyPress={(event: any) => {
            if (event.code === "Minus") {
              event.preventDefault();
            }
          }}
        />
      ),
    },
    actionsCol,
  ];
};

interface TotalsProps {
  totals: {
    option_qty: number,
    executed_order_qty: number,
    allowed_position: number,
    custodians: { [key: string]: number }
  } | null;
}

const Totals = ({ totals }: TotalsProps) => {
  if (!totals) {
    return null;
  }

  return (
    <Grid container sx={{ paddingY: 1 }}>
      <Grid item sx={{ paddingX: 2, paddingBottom: 1 }}>
        <Typography variant="h6" component="h6">
          Total Option Qty: {totals.option_qty + totals.executed_order_qty}
        </Typography>
      </Grid>
      <Grid item sx={{ paddingX: 2, paddingBottom: 1 }}>
        <Typography variant="h6" component="h6">
          Total Allowed Position: {totals.allowed_position}
        </Typography>
      </Grid>
      {JSON.stringify(totals.custodians) !== "{}" && (
        <>
          <Grid item sx={{ paddingLeft: 2 }} xs={12}>
            <Divider sx={{ marginBottom: 1 }} />
          </Grid>
          {Object.keys(totals.custodians).sort().map((custodian: string) => {
            return (
              <Grid item sx={{ paddingLeft: 2 }} xs={12}>
                <Typography variant="h6" component="h6">
                  {custodian}: {totals.custodians[custodian]}
                </Typography>
              </Grid>
            )
          })}
        </>
      )}
    </Grid>
  );
};

const Toolbar = ({ totals}: TotalsProps) => {
  return <Totals totals={totals} />;
};

const Footer = ({ totals }: TotalsProps) => {
  return (
    <>
      <Totals totals={totals} />
      <GridFooter />
    </>
  );
};

export default function StrategyPositionsList() {
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAuth();
  const [updateAccountStrategy] = useUpdateAccountStrategyMutation();
  const [overridePosition] = useOverridePositionQueryMutation();
  const [removeAccountFromStrategy, { isLoading: isRemoveAccountFromStrategyLoading }] = useRemoveAccountFromStrategyMutation();
  const { currentStrategy, currentSettlementDate, tradeMode, draftOrders, positionsPollingInterval, orderType, limitPrice } =
    useTrading();
  const { compPreviewParams } = useReporting();
  const [sorting, setSorting] = useSorting("client__name");
  const [pagination, setPagination] = usePagination();
  const [gridFilters, setGridFilters] = useGridFilters();

  const { data: accounts, isFetching } = useListStrategyPositionsQuery(
    {
      pagination,
      sorting,
      gridFilters,
      ...{
        filters: {
          strategy_id: currentStrategy?.id,
          as_of_date: currentSettlementDate,
        },
      },
    },
    {
      pollingInterval: positionsPollingInterval,
      skipPollingIfUnfocused: true,
      skip: !isAuthenticated || !currentStrategy,
      selectFromResult: ({ data, ...rest }) => ({
        data: {
          ...data,
          results: data?.results.map((strategyPosition: StrategyPosition) => ({
            ...strategyPosition,
            allowed_position: -Math.floor(
              (parseFloat(
                ((strategyPosition.available_stock_qty === null
                  ? strategyPosition.stock_qty
                  : strategyPosition.available_stock_qty) as string) || "0",
              ) *
                parseFloat(currentStrategy?.hedge_ratio || "0")) /
                100,
            ),
          })),
        },
        ...rest,
      }),
    },
  );

  useEffect(() => {
    if (currentStrategy !== undefined && accounts?.results !== undefined) {
      const draftOrders: DraftOrder[] = [];
      accounts?.results?.forEach((account) => {
        let quantity = 0;
        const onOrder = getOnOrder(account);
        const optionQty = getOptionQty(account) || 0;
        const currentQty = optionQty + onOrder;
        const positionDiff = account.allowed_position - currentQty; // Difference between allowed position and current position

        if (tradeMode === TradeMode.CallSellShort) {
          // For Sell Short only sell if allowed
          if (positionDiff < 0) {
            quantity = positionDiff;
          } else {
            quantity = 0;
          }
        } else if (tradeMode === TradeMode.CallBuyToCover) {
          // For BuyToCover only close existing positions
          if (currentQty < 0) {
            // Open short position exists
            quantity = -currentQty;
          } else {
            quantity = 0;
          }
        } else if (tradeMode === TradeMode.CallRebalance) {
          // For Rebalance adjust existing posistions to match allowed position
          if (currentQty < 0) {
            // Open short position exists
            quantity = positionDiff;
          } else {
            // No open short position exists
            quantity = 0;
          }
        }

        if (quantity === 0 || isNaN(quantity)) {
          return;
        }

        draftOrders.push({
          account: account.id,
          asset: currentStrategy?.current_call!,
          quantity: quantity,
          type: orderType,
          price: orderType === OrderType.LIMIT ? limitPrice : null,
          side: quantity > 0 ? "buy" : "sell",
          custodian_name: account.custodian__name,
        });
      });
      dispatch(setDraftOrders(draftOrders));
    }
  }, [currentStrategy, tradeMode, isFetching, orderType, limitPrice, OrderType]);

  const [removeAccountParams, setRemoveAccountParams] = useState<RemoveAccountFromStrategyParams | null>(null);
  const [popoverAnchor, setPopoverAnchor] = useState(null);

  const onRemoveAccountConfirm = useCallback(() => {
    if (removeAccountParams) {
      removeAccountFromStrategy(removeAccountParams);
      setRemoveAccountParams(null);
      setPopoverAnchor(null);
    }
  }, [removeAccountParams, removeAccountFromStrategy, setRemoveAccountParams, setPopoverAnchor]);

  const onRemoveAccountCancel = useCallback(() => {
    setRemoveAccountParams(null);
    setPopoverAnchor(null);
  }, [setRemoveAccountParams, setPopoverAnchor]);

  const onRemoveAccountClick = useCallback((id: number) => (e: any) => {
    let params: RemoveAccountFromStrategyParams = { id };
    if (currentStrategy) {
      if (currentStrategy.strategy_group === StrategyGroupEnum.STANDARD) {
        params = { ...params, strategy_id: currentStrategy.id };
        setRemoveAccountParams({ ...params });
      } else {
        params = { ...params, group_id: currentStrategy.strategy_group };
        setRemoveAccountParams({ ...params });
      }
      setPopoverAnchor(e?.currentTarget);
    }
  }, [currentStrategy, StrategyGroupEnum, setRemoveAccountParams, setPopoverAnchor]);

  const composite = useMemo(() => {
    if (!currentStrategy) {
      return null;
    }

    return CompositesData.find(
      (item) =>
        (item.is_multi_stock && item.strategy_group_id === currentStrategy.strategy_group)
      || (!item.is_multi_stock && item.strategy_id === currentStrategy.id)
    ) || null;
  }, [currentStrategy]);

  useEffect(() => {
    return () => { dispatch(setCompPreviewParams(DEFAULT_COMP_PREVIEW_PARAMS)) };
  }, []);

  const [isCRPModalOpen, setIsCRPModalOpen] = useState(false);

  const openCompPreviewModal = useCallback((id: number) => (e: any) => {
    dispatch(setCompPreviewParams({ ...compPreviewParams, composite_id: composite?.id, account_id: id }));
    setIsCRPModalOpen(true);
  }, [dispatch, setCompPreviewParams, compPreviewParams, setIsCRPModalOpen, composite]);

  const closeCompPreviewModal = () => {
    setIsCRPModalOpen(false);
    dispatch(setCompPreviewParams(DEFAULT_COMP_PREVIEW_PARAMS));
  };

  const actionsCol: GridColDef<StrategyPosition> = useMemo(() => {
    return {
      field: "actions",
      headerName: "Actions",
      minWidth: 100,
      renderCell: ({ row: { id } }) => {
        const mountPopover = popoverAnchor && removeAccountParams?.id === id;
        return (
          <>
            <Tooltip title="Remove account from strategy">
              <>
                <IconButton
                  onClick={onRemoveAccountClick(id)}
                  disabled={!currentStrategy || isRemoveAccountFromStrategyLoading}
                >
                  <RemoveIcon />
                </IconButton>
                {mountPopover && (
                  <Popover
                    open={!!removeAccountParams}
                    anchorEl={popoverAnchor}
                    onClose={onRemoveAccountCancel}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <Box component="div" sx={{ padding: 1 }}>
                      <Typography sx={{ padding: 1 }}>
                        Remove this account from selected strategy?
                      </Typography>
                      <Grid container justifyContent="space-evenly">
                        <Grid item>
                          <IconButton onClick={onRemoveAccountConfirm}>
                            <ConfirmIcon />
                          </IconButton>
                        </Grid>
                        <Grid item>
                          <IconButton onClick={onRemoveAccountCancel}>
                            <CancelIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Box>
                  </Popover>
                )}
              </>
            </Tooltip>
            {composite && (
              <Tooltip title="Get composite report preview">
                <IconButton onClick={openCompPreviewModal(id)}>
                  <SummarizeIcon />
                </IconButton>
              </Tooltip>
            )}
          </>
        );
      },
    };
  },[
    popoverAnchor,
    removeAccountParams,
    onRemoveAccountClick,
    currentStrategy,
    isRemoveAccountFromStrategyLoading,
    onRemoveAccountCancel,
    onRemoveAccountConfirm,
  ]);

  const columns = createColumns(
    tradeMode,
    parseFloat(currentStrategy?.hedge_ratio || "0"),
    draftOrders!,
    actionsCol,
  );

  const totals = useMemo(() => {
    if (!accounts || !accounts.results) {
      return null;
    }

    const { results } = accounts;
    return results.reduce((acc: any, row: StrategyPosition) => {
      const optionQty = getOptionQty(row) || 0;
      acc.option_qty += optionQty;
      const executedQty = getExecutedOrder(row);
      acc.executed_order_qty += executedQty;
      acc.allowed_position += (row as any).allowed_position;
      const { custodian__comp_id: custodian } = row;
      if (custodian) {
        if (acc.custodians[custodian]) {
          acc.custodians[custodian] += (optionQty + executedQty);
        } else {
          acc.custodians[custodian] = optionQty + executedQty;
        }
      }

      return acc;
    }, {
      option_qty: 0,
      executed_order_qty: 0,
      allowed_position: 0,
      custodians: {},
    });
  }, [accounts]);

  return (
    <>
      <ServerDataGrid
        slots={{ toolbar: Toolbar, footer: Footer }}
        slotProps={{ toolbar: { totals }, footer: { totals } as FooterPropsOverrides }}
        rows={accounts?.results || []}
        rowCount={accounts?.count || 0}
        loading={isFetching}
        columns={columns}
        paginationModel={pagination}
        sortModel={sorting}
        filterModel={gridFilters}
        onSortModelChange={setSorting}
        onFilterModelChange={setGridFilters}
        onPaginationModelChange={setPagination}
        density="compact"
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
              client: false,
              reg_type__name: false,
              client__representative__name: false,
              new_option_pos: false,
              orion_account_id: false,
            },
          },
        }}
        onCellEditStop={(params: any, event: any) => {
          const {
            field,
            row: { id, account_strategy_id },
          } = params;
          const value = event.target.value;
          if (field === "available_stock_qty") {
            updateAccountStrategy({
              id: account_strategy_id,
              available_quantity: Math.abs(parseInt(value)),
            });
          } else if (field === "option_qty") {
            const quantity = value !== "0" && !value
              ? null
              : (parseInt(event.target.value) * 100).toFixed(0);
            overridePosition({
              account_id: id,
              asset_id: currentStrategy?.current_call || -1,
              quantity,
            })
          }
        }}
      />
      {isCRPModalOpen && <CRPModal isOpened={isCRPModalOpen} onClose={closeCompPreviewModal} />}
    </>
  );
}
