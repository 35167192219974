import { Box, Divider, Grid, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { CompositeReportPreview } from "features/trading/accounts"
import { formatPercent, mapOptionNameFromParams, formatUsDate, parseOptionSymbol, formatReportNumber } from "helpers/index";

interface Props{
  data: CompositeReportPreview;
}

export const ReportView = ({ data }: Props) => {
  const { account_number, start_date, end_date, start_value, end_value, perfomance, shares_start_value, shares_end_value, cash_flow, shares_data, orders_info } = data;
  const orderColumns: GridColDef[] = [
    {
      field: "symbol",
      headerName: "Name",
      width: 250,
      valueFormatter: ({ value }) => {
        const symbolParams = parseOptionSymbol(value);
        return mapOptionNameFromParams(symbolParams);
      },
    },
    {
      field: "created_on_date",
      headerName: "Order Date",
      width: 120,
      valueFormatter: ({ value }) => formatUsDate(value),
    },
    {
      field: "side",
      headerName: "Side",
    },
    {
      field: "qty",
      headerName: "Qty"
    },
    {
      field: "price",
      headerName: "Price",
    },
    {
      field: "change",
      headerName: "Change",
      align: "right",
      renderCell: ({ value }: GridRenderCellParams) => (
        <Box
          component="span"
          style={{ color: value >= 0 ? "green" : "red" }}
        >
          {formatReportNumber(value)}
        </Box>
      ),
    },
  ]
  return (
    <Grid container>
      <Grid item>
        <Typography>
          Account number: <b>{account_number}</b>
        </Typography>
        <Typography>
          Start Date: <b>{start_date}</b>
        </Typography>
        <Typography>
          End Date: <b>{end_date}</b>
        </Typography>
        <Typography>
          Start Value: <b>{formatReportNumber(start_value)}</b>
        </Typography>
        <Typography>
          End Value: <b>{formatReportNumber(end_value)}</b>
        </Typography>
        <Typography>
          Perfomance: <b>{formatPercent(perfomance)}</b>
        </Typography>
        <Divider sx={{ marginY: 1}}/>
        <Typography fontSize="large">Values details</Typography>
        <Typography>
          Start shares value: <b>{formatReportNumber(shares_start_value)}</b>
        </Typography>
        <Typography>
          End shares value: <b>{formatReportNumber(shares_end_value)}</b>
        </Typography>
        <Typography>
          Cash flow: <b>{formatReportNumber(cash_flow)}</b>
        </Typography>
        <Divider sx={{ marginY: 1}}/>
        <Typography fontSize="large">Shares info</Typography>
        <Grid container>
        {shares_data.map(item => {
          const { symbol, data: { start, end } } = item;
          const startDate = formatUsDate(start.date);
          const endDate = formatUsDate(end.date);
          const startPrice = formatReportNumber(start.price);
          const endPrice = formatReportNumber(end.price);
          return (
            <Grid item sx={{ marginY: 1, width: '100%' }}>
              <Typography fontWeight="bold">{symbol} {start.overriden || end.overriden && "(amount overriden)"}</Typography>
              <Typography sx={{ paddingLeft: 1}}>Date: <b>{startDate}</b>, Amount: <b>{start.amount}</b>, Price: <b>{startPrice}</b></Typography>
              <Typography sx={{ paddingLeft: 1}}>Date: <b>{endDate}</b>, Amount: <b>{end.amount}</b>, Price: <b>{endPrice}</b></Typography>
            </Grid>
          );
        })}
        </Grid>
        <Divider sx={{ marginY: 1}}/>
        <Typography fontSize="large">Orders info</Typography>
        <Grid>
          <DataGrid
            rows={orders_info}
            rowCount={orders_info.length}
            columns={orderColumns}
            density="compact"
            hideFooter
          />
        </Grid>
      </Grid>
    </Grid>
  )
}