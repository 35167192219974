import { Box, Button, Card, CircularProgress, Grid, IconButton, InputLabel, Modal, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAppDispatch, useReporting } from "features/store";
import { DatePicker } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";
import { setCompPreviewParams } from "features/reporting/reportingSlice";
import { useState } from "react";
import { useGetCompositeReportPreviewQuery } from "features/trading/accounts";
import { ReportView } from "./ReportView";

interface Props {
  isOpened: boolean;
  onClose: () => void;
}

const DEFAULT_REPORTED_YM = [null, null];

export const CRPModal = ({ isOpened, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const { compPreviewParams } = useReporting();
  const [reportedYM, setReportedYM] = useState<(number | null)[]>(DEFAULT_REPORTED_YM);
  const onYMChange = (value: Dayjs | null) => {
    if (!value) {
      setReportedYM(DEFAULT_REPORTED_YM);
      return;
    }

    setReportedYM([value.year(), value.month() + 1]);
  };

  const updateYMParams = () => {
    const [year, month] = reportedYM;
    dispatch(setCompPreviewParams({ ...compPreviewParams, year, month }));
  }

  const { composite_id, account_id, year, month } = compPreviewParams;

  const { data, isLoading } = useGetCompositeReportPreviewQuery(
    compPreviewParams as any,
    { skip: !composite_id || !account_id || !year || !month }
  );

  return (
    <Modal open={isOpened} onClose={onClose}>
      <Stack height="100%" alignItems="center" justifyContent="center">
        <Box component="div" sx={{ width: "1200px" }}>
          <Card>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="h5" component="h5" sx={{ padding: 1 }}>
                  Composite Report Preview
                </Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid container sx={{ paddingY: 1, paddingLeft: 1 }} alignItems="center">
              <Grid item marginRight={2}>
                <InputLabel>Reported month:</InputLabel>
              </Grid>
              <Grid item marginRight={2}>
                <DatePicker
                  views={['year', 'month']}
                  format="MM/YYYY"
                  onChange={onYMChange}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  size="large"
                  onClick={() => updateYMParams()}
                >
                  Get
                </Button>
              </Grid>
            </Grid>
            <Grid container sx={{ paddingY: 1, paddingLeft: 1, height: "800px", overflowY: "scroll" }}>
              {isLoading && <CircularProgress />}
              {data && (
                <ReportView data={data} />
              )}
            </Grid>
          </Card>
        </Box>
      </Stack>
    </Modal>
  )
};
