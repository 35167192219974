import { useCallback, useMemo } from "react";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import {
  GridColDef,
  GridEditInputCell,
  GridRenderCellParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import { ServerDataGrid } from "components/ui/ServerDataGrid";
import dayjs from "dayjs";
import {
  Strategy,
  StrategyGroup,
  useUpdateStrategyMutation,
} from "features/trading/accounts";
import {
  setCurrentStrategy,
  setCurrentStrategyGroup,
} from "features/trading/tradingSlice";
import { useNavigate } from "react-router-dom";
import { asCurrency } from "components/ui/formatters";
import { useAppDispatch } from "features/store";
import { formatFactsetNumberValue, getDaysToEarningsDate, getDaysToExDividend, getHomepageDateColumnHighlightColor, mapOptionNameFromParams } from "helpers";

interface Props {
  group: StrategyGroup;
  isLoading: boolean;
  isFetching: boolean;
  rows: Strategy[];
}

export const StrategyGroupTable = ({ group, isLoading, isFetching, rows }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [updateStrategy] = useUpdateStrategyMutation();

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Position",
      renderCell: ({ value, row }: GridRenderCellParams) => {
        return (
          <Box
            component="span"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              const { strategy_group } = row;
              dispatch(setCurrentStrategyGroup(strategy_group));
              dispatch(setCurrentStrategy(row));
              navigate("/stock");
            }}
          >
            {value}
          </Box>
        );
      },
    }, // DB
    {
      field: "",
      width: 220,
      headerName: "Current Call",
      renderCell: ({ row: { current_call, optionSymbolParams } }: GridRenderCellParams) => {
        const isOpen = Boolean(!current_call);
        if (isOpen) {
          return <Box component="span" sx={{ color: "green" }}>Open</Box>;
        }

        if (!optionSymbolParams.isCorrect) {
          return <Box component="span" sx={{ color: "red" }}>Incorrect Option Symbol</Box>;
        }

        const { expirationDate } = optionSymbolParams;

        const daysDiff = expirationDate.diff(dayjs(), "day");
        const styles = daysDiff === 0 ? { backgroundColor: "#7FFF00" } : daysDiff < 7 ? { backgroundColor: "yellow" } : null;
        return (
          <Box component="span" sx={styles}>
            {mapOptionNameFromParams(optionSymbolParams)}
          </Box>
        );
      },
    }, // DB
    {
      field: "last_price",
      headerName: "Last Price",
      renderCell: ({ value, row: { current_call, optionSymbolParams } }: GridRenderCellParams) => {
        const needCheckITM = current_call && optionSymbolParams.isCorrect && value && value !== "N/A";
        if (needCheckITM && optionSymbolParams.strikePrice < parseFloat(value)) {
          return <Box component="span" sx={{ backgroundColor: "#7FFF00", fontWeight: "bold", width: "100%", textAlign: "center" }}>
            {value}
          </Box>
        }

        return value;
      },
    },
    { field: "option_price", width: 120, headerName: "Option Price" }, // FC
    {
      field: "cost_basis",
      headerName: "Basis",
      type: "number",
      editable: true,
      renderEditCell: (params: any) => (
        <GridEditInputCell
          {...params}
          inputProps={{
            min: 0.01,
            step: 0.01,
          }}
          onKeyPress={(event: any) => {
            if (event.code === "Minus") {
              event.preventDefault();
            }
          }}
        />
      ),
    },
    {
      field: "gain_n_loss_per_contract",
      headerName: "G/L",
      renderCell: ({ value }: GridRenderCellParams) => (
        <Box
          component="span"
          style={{ color: value > 0 ? "green" : value === 0 ? "black" : "red" }}
        >
          {value}
        </Box>
      ),
    }, // Calculated
    {
      field: "totalGL",
      headerName: "Total G/L",
      width: 150,
      renderCell: ({ value }: GridRenderCellParams) => {
        const fontColor = value === "N/A" || value === 0 ? "inherit" : value > 0 ? "green" : "red";
        const formattedValue = value !== "N/A" ? asCurrency(value) : value;
        return <Box component="span" sx={{ color: fontColor }}>{formattedValue}</Box>
      },
    }, // Calculated
    { field: "delta_mid",
      headerName: "Delta Mid",
      valueFormatter: ({ value }: GridValueFormatterParams) => formatFactsetNumberValue(value),
    },
    {
      field: "position_delta",
      width: 120,
      headerName: "Position Delta",
      valueFormatter: ({ value }: GridValueFormatterParams) => formatFactsetNumberValue(value),
    },
    {
      field: "dividend_ex_date",
      width: 120,
      headerName: "DVD EX DT",
      renderCell: ({ row, value }: GridRenderCellParams) => {
        if (!value) {
          return (<Box component="span">N/A</Box>);
        }

        const daysTo = getDaysToExDividend(row)
        const backgroundColor = getHomepageDateColumnHighlightColor(daysTo);

        return (
          <Box component="span" sx={{ backgroundColor, width: "100%", textAlign: "center" }}>
            {value}
          </Box>
        );
      },
    }, // FC
    {
      field: "dividend_amt",
      headerName: "DIV AMT",
      valueFormatter: ({ value }: GridValueFormatterParams) => formatFactsetNumberValue(value),
    }, // FC
    {
      field: "days_to_ex_dividend",
      width: 120,
      headerName: "Days to Ex Div",
      valueFormatter: ({ value }: GridValueFormatterParams) => value && value >= 0 ? value : "N/A",
    }, // Calculated
    {
      field: "next_earnings_date",
      width: 120,
      headerName: "Earnings DT",
      renderCell: ({ row, value }: GridRenderCellParams) => {
        if (!value) {
          return (<Box component="span">N/A</Box>);
        }

        const daysTo = getDaysToEarningsDate(row)
        const backgroundColor = getHomepageDateColumnHighlightColor(daysTo);

        return (
          <Box component="span" sx={{ backgroundColor, width: "100%", textAlign: "center" }}>
            {value}
          </Box>
        );
      },
    },
    {
      field: "aum",
      headerName: "AUM",
      width: 150,
      valueFormatter: ({ value }: GridValueFormatterParams) =>
        asCurrency(value),
    }, // Calculated
  ].map((col) => ({
    ...col,
    align: "center",
    headerAlign: "center",
    sortable: false,
  }));

  const totalGL = useMemo(() => {
    return rows.reduce((acc: any, cur: any) => {
      if (cur?.totalGL === "N/A") {
        return acc;
      }

      return acc + parseFloat(cur?.totalGL || 0);
    }, 0);
  }, [rows]);

  const totalAUM = useMemo(() => {
    const total = rows.reduce((acc: any, cur: any) => {
      return acc + parseFloat(cur?.aum! || 0);
    }, 0);

    return asCurrency(total);
  }, [rows]);

  const renderTotals = useCallback(() => {
    const totalGlColor =
      totalGL === 0
      ? "inherit"
      : totalGL > 0
      ? "green"
      : "red";
    return (
      <Grid container>
        <Grid item>
          <Typography variant="h5" component="h5" sx={{ margin: 2 }}>
            Total G/L: <span style={{ color: totalGlColor }}>{asCurrency(totalGL)}</span>
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h5" component="h5" sx={{ margin: 2 }}>
            Total AUM: {totalAUM}
          </Typography>
        </Grid>
      </Grid>
    );
  }, [totalGL, totalAUM, asCurrency]);

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Grid>
      <Typography variant="h4" component="h4">
        {group.name}
      </Typography>
      <ServerDataGrid
        rows={rows}
        // rowCount={data.count}
        loading={isLoading || isFetching}
        columns={columns}
        density="compact"
        slots={{
          footer: renderTotals,
        }}
        onCellEditStop={(params: any, event: any) => {
          const {
            field,
            row: { id }
          } = params;
          if (field === "cost_basis") {
            const newValue = parseFloat(event.target.value);
            updateStrategy({
              id,
              current_call_price: newValue ? newValue.toFixed(2) : null,
            });
          }
        }}
      />
    </Grid>
  );
};
