export enum ReportingFrequency {
  MONTHLY = "Monthly",
  QUARTERLY = "Quarterly",
  SEMI_ANNUAL = "Semi-Annual",
  ANNUAL = "Annual",
}

export enum UnderlyingType {
  COVERED = "Covered",
  UNCOVERED = "Uncovered",
}

export enum ManagementType {
  DIRECT = "Direct",
  SOLICITATION = "Solicitation",
  SUB_ADVISOR = "Sub-Advisor",
}

export enum OrderStatus {
  DRAFT = "draft",
  NEW = "new",
  STAGED = "staged",
  OPEN = "open",
  REJECTED = "rejected",
  CANCELED = "canceled",
  ERROR = "error",
  PARTIALLY_FILLED = "partially_filled",
  FILLED = "filled",
}

export enum FormMode {
  CREATE,
  EDIT,
}

export enum StrategyGroupEnum {
  STANDARD = 1,
  DIV_OPTIC = 2,
  GROWTH_OPTIC = 3,
}

export const DEFAULT_POLLING_INTERVAL = 60_000;

export enum LocalStorageKeys {
  LAST_STRATEGY_ID = "lastStrategyId",
  LAST_STRATEGY_GROUP_ID = "lastStrategyGroupId",
}

export enum OrderType {
  MARKET = "market",
  LIMIT = "limit",
}

export const DEFAULT_LIMIT_PRICE = 0.00;

export const NOTISTACK_AUTO_HIDE_DURATION = 5_000;
export const NOTISTACK_MAX_SNACK = 3;

export const NOTISTACK_SUCCESS_OPTIONS = {
  variant: "success",
  anchorOrigin: { horizontal: "right", vertical: "bottom" },
};
export const NOTISTACK_FAILURE_OPTIONS = {
  variant: "error",
  anchorOrigin: { horizontal: "right", vertical: "bottom" },
};

export const NOTISTACK_ALERT_OPTIONS = {
  variant: "info",
  anchorOrigin: { horizontal: "right", vertical: "bottom" },
  persist: true,
};

export enum FDS_ActionTypes {
  GET = "GET",
  SUBSCRIBE = "SUBSCRIBE",
  UNSUBSCRIBE = "UNSUBSCRIBE",
}

export enum FDS_SubscriptionTypes {
  HOMEPAGE_STOCK = "HOMEPAGE_STOCK",
  HOMEPAGE_OPTION = "HOMEPAGE_OPTION",
  OPTION_CONTRACT = "OPTION_CONTRACT",
  OPTION_CHAIN = "OPTION_CHAIN",
  STOCK_PRICE = "STOCK_PRICE",
}

export const VolDaysOptions = [
  {
    label: "1 Year",
    value: 360,
  },
  {
    label: "3 Years",
    value: 1080,
  },
  {
    label: "5 Years",
    value: 1800,
  },
];


// TODO Remove hardcoded composites data
export const CompositesData = [
  {
    id: 1,
    name: "SPY Optic",
    is_multi_stock: false,
    strategy_id: 1,
  },
  {
    id: 2,
    name: "TLT Optic",
    is_multi_stock: false,
    strategy_id: 2,
  },
  {
    id: 3,
    name: "Div Optic",
    is_multi_stock: true,
    strategy_group_id: 2,
  },
  {
    id: 4,
    name: "Growth Optic",
    is_multi_stock: true,
    strategy_group_id: 3
  },
];
