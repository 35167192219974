import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import authReducer from "features/auth/authSlice";
import layoutReducer from "features/layout/layoutSlice";
import tradingReducer from "features/trading/tradingSlice";
import { apiWithAuth } from "features/auth/auth";
import api from './api';
import alertsReducer from "features/alerts/alertsSlice";
import factsetStreamingReducer from "./factset-streaming/factsetStreamingSlice";
import reportingReducer from "./reporting/reportingSlice";

const authMiddleware = (store:any) => (next:any) => (action:any) => {
    if (apiWithAuth.endpoints.login.matchFulfilled(action)) {
        localStorage.setItem('accessToken', action.payload.access);
        localStorage.setItem('refreshToken', action.payload.refresh);
    } else if (apiWithAuth.endpoints.logout.matchFulfilled(action)) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
    }
    return next(action);
};

export const store = configureStore({
  reducer: {
    auth: authReducer,
    layout: layoutReducer,
    trading: tradingReducer,
    [api.reducerPath]: api.reducer,
    alerts: alertsReducer,
    factsetStreaming: factsetStreamingReducer,
    reporting: reportingReducer,
  },
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(authMiddleware , api.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAuth = () => useAppSelector((state) => state.auth);
export const useLayout = () => useAppSelector((state) => state.layout);
export const useTrading = () => useAppSelector((state) => state.trading);
export const useAlerts = () => useAppSelector((state) => state.alerts);
export const useFactsetStreaming = () => useAppSelector((state) => state.factsetStreaming);
export const useReporting = () => useAppSelector((state) => state.reporting);
